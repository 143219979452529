import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/index-page"
import FeaturesComponent from "../../components/pages/main/landing-features"
import PricingComponent from "../../components/pages/main/detail-pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import Banner from "../../components/pages/main/banner"
import Cards from "../../components/pages/main/cards"
import MainPageInfo from "../../components/pages/main/info"
import Discount from "../../components/pages/main/discount"
import SportAccordionComponent from "../../components/pages/sport/accordion"
import Breadcrumb from "../../components/controls/breadcrumbs"

const KinderSportsPage = (props) => {
        const crumbs = [
                { name: 'Главная', url: '/'},
                { name: 'Спорт', url: ''}
        ];
        return (
          <Layout headerColor={"#6D20D2"} page={"landing_sports"}>
                  <SEO title="CRM-система для спортивных школ и секций" noindex="noindex"
                       description="Удобная CRM для спортивных школ и секций. Онлайн расписание занятий, учет абонементов, посещаемости, прием платежей и многое другое. Бесплатный тестовый период 10 дней."
                       url={"https://paraplancrm.ru" + props.location.pathname}
                       ogImage={"https://paraplancrm.ru/images/seo/sports.png"}
                  />
                  <HeroFeaturePage
                    title='CRM-система учета'
                    subtitle="для спортивных школ и секций"
                    idUnderBlock="#features"
                    offsetHeader={80}
                    list={[
                            '<b>Автоматизирует</b> процессы: от учета посещаемости <br/> до приема оплат.',
                            '<b>Облегчает нагрузку</b> руководителя.',
                            'Увеличивает <b>доходы</b>.',
                            'Помогает <b>контролировать</b> администраторов и <br/> тренеров.',
                    ]} />
                  <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
                  <FeaturesComponent title='Все, что нужно' subtitle="Не только инструменты для работы с заявками и контроля отдела продаж" />
                  <Discount />
                  <MainPageInfo
                    title='Работа с CRM - это переход на новый уровень'
                    subtitle='Параплан создан специально для ведения учета клиентов и продаж спортивных школ, тренажерных залов, секций футбола, хоккея, единоборств, гимнастики, фитнес-центров и спортивных клубов.' />
                  <Cards />
                  <Banner />
                  <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
                  <ContactFormComponent />
                  <SportAccordionComponent />
          </Layout>
        )
}

export default KinderSportsPage
